import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import classNames from 'classnames'
import IconButton from '@material-ui/core/IconButton'
import Icon from '@material-ui/core/Icon'
import queryString from 'query-string'
import AnalyticsEvents from 'shared-ui/utils/AnalyticsEvents'
import { getDeviceType } from 'shared-ui/utils/deviceDetector'
import { useMember } from 'shared-ui/components/pages/PageWrapper'
import { useAppFrame } from '../../AppFrameContext'

const styles = ({ palette, breakpoints }) => ({
  root: {
    position: 'fixed',
    zIndex: 1,
    bottom: 20,
    left: 20,
    transition: '0.3s',
    '@media print': {
      display: 'none',
    },
  },
  open: {
    transform: 'none !important',
    width: 400,
    [breakpoints.down('xs')]: {
      width: 'calc(100% - 20px)',
      height: 'calc(100% - 20px)',
      bottom: 10,
      left: 10,
    },
  },
  '@keyframes pulse': {
    '0%': {
      transform: 'scale(0.95)',
      boxShadow: '0 0 0 0 rgba(100, 100, 100, 0.7)',
    },
    '70%': {
      transform: 'scale(1)',
      boxShadow: '0 0 0 10px rgba(100, 100, 100, 0)',
    },
    '100%': {
      transform: 'scale(0.95)',
      boxShadow: '0 0 0 0 rgba(100, 100, 100, 0)',
    },
  },
  button: {
    height: 48,
    borderRadius: 24,
    display: 'flex',
    alignItems: 'center',
    color: palette.paper,
    animation: 'pulse 2s infinite',
  },
  buttonWithLabel: {
    padding: '0 1rem',
  },
  buttonLabel: {
    fontSize: '1rem',
    fontWeight: 'bold',
    paddingLeft: '0.4em',
  },
  hidden: {
    opacity: 0,
  },
  iframe: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    flex: 1,
    border: 0,
    height: 0,
    width: 0,
    transition: '0.3s',
    opacity: 0,
    pointerEvents: 'none',
  },
  iframeOpen: {
    opacity: 1,
    pointerEvents: 'auto',
    boxShadow: '0 0 5px rgba(0,0,0,0.5)',
    width: 400,
    height: 500,
    maxWidth: '100%',
    maxHeight: 'calc(100vh - 40px)',
    [breakpoints.down('xs')]: {
      width: '100%',
      height: '100%',
      maxHeight: '100%',
      bottom: 0,
      left: 0,
      borderRadius: 0,
    },
  },
})

export default function Chatbot() {
  const member = useMember()
  const appFrame = useAppFrame()
  if (!member) return null

  const technical = appFrame?.settings?.technical

  if (!technical) return null

  const {
    chatbotEnabled,
    chatbotPath,
    chatbotColor,
    chatbotInputPlaceholder,
    chatbotNickname,
    chatbotSubtitle,
    chatbotButtonLabel,
    chatbotToken,
    chatbotAvatar,
  } = technical

  if (!chatbotEnabled || !chatbotToken) return null

  const chatbot = {
    path: chatbotPath,
    color: chatbotColor ?? 'var(--accent-400)',
    placeholder: chatbotInputPlaceholder,
    nickname: chatbotNickname,
    subtitle: chatbotSubtitle,
    buttonLabel: chatbotButtonLabel,
    token: chatbotToken,
    avatar: chatbotAvatar,
    member,
  }

  return <ChatbotComponent {...chatbot} />
}

const ChatbotComponent = withStyles(styles)(props => {
  const {
    classes,
    path,
    color,
    placeholder = '',
    buttonLabel,
    avatar = {},
    nickname,
    subtitle,
    token,
    member,
  } = props

  const { isMobile } = getDeviceType()
  const [open, setOpen] = React.useState(false)

  React.useEffect(() => {
    const timer = setTimeout(function () {
      const button = document.getElementById('animated-btn')
      if (button) {
        button.style.animation = 'none'
      }
    }, 5000)

    return () => {
      clearTimeout(timer)
    }
  }, [])

  React.useEffect(() => {
    if (!token) return

    function sendToken(e) {
      if (e.data !== 'start-chatbot') return

      // send token to iframe
      const iframe = document.getElementById('ChatbotIframe')
      iframe.contentWindow.postMessage({ id: 'token', token }, '*')
    }

    function closeChatbot(e) {
      if (e.data === 'close-chatbot') setOpen(false)
    }

    window.addEventListener('message', closeChatbot)
    window.addEventListener('message', sendToken)

    return () => {
      window.removeEventListener('message', closeChatbot)
      window.removeEventListener('message', sendToken)
    }
  }, [token])

  if (!path || !token) return null

  const srcUrl = `${path}?${queryString.stringify({
    p: placeholder,
    a: avatar.path,
    n: nickname,
    s: subtitle,
    m: member._id,
  })}`

  return (
    <aside id="Chatbot" className={classNames(classes.root, { [classes.open]: open })}>
      <IconButton
        className={classNames(
          classes.button,
          { [classes.buttonWithLabel]: buttonLabel },
          { [classes.hidden]: open },
        )}
        onClick={() => {
          setOpen(true)
          AnalyticsEvents.pushChatBotOpen()
        }}
        id="animated-btn"
        style={{ backgroundColor: color }}
      >
        <Icon className={classes.icon}>chat_bubble_outline</Icon>
        {buttonLabel && !isMobile && <span className={classes.buttonLabel}>{buttonLabel}</span>}
      </IconButton>
      <iframe
        className={classNames(classes.iframe, { [classes.iframeOpen]: open })}
        id="ChatbotIframe"
        title="Chatbot"
        src={srcUrl}
      ></iframe>
    </aside>
  )
})
