import { getLuminance } from '@material-ui/core/styles/colorManipulator'

function withOpacity(color, opacity) {
  const r = parseInt(color.slice(1, 3), 16),
    g = parseInt(color.slice(3, 5), 16),
    b = parseInt(color.slice(5, 7), 16)

  return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + opacity + ')'
}

const Colors = {
  // DEFAULT COLORS
  White: '#ffffff',
  Black: '#000000',

  // header backgrounds
  backgrounds: {
    accent100: {
      background: 'var(--accent-100)',
      textClass: '!text-contrast-100',
    },
    accent200: {
      background: 'var(--accent-200)',
      textClass: '!text-contrast-200',
    },
    accent300: {
      background: 'var(--accent-300)',
      textClass: '!text-contrast-300',
    },
    accent400: {
      background: 'var(--accent-400)',
      textClass: '!text-contrast-400',
    },
    accent500: {
      background: 'var(--accent-500)',
      textClass: '!text-contrast-500',
    },
    spectrum1: {
      background:
        'linear-gradient(90deg, rgba(77,185,100,1) 0%, rgba(231,179,80,1) 49%, rgba(236,148,157,1) 100%)',
      textClass: '!text-paper',
    },
    spectrum2: {
      background:
        'linear-gradient(90deg, rgba(226,83,101,1) 0%, rgba(236,108,72,1) 49%, rgba(209,144,172,1) 100%)',
      textClass: '!text-paper',
    },
    spectrum3: {
      background:
        'linear-gradient(90deg, rgba(189,173,215,1) 0%, rgba(135,81,159,1) 49%, rgba(177,127,157,1) 100%)',
      textClass: '!text-paper',
    },
    spectrum4: {
      background:
        'linear-gradient(90deg, rgba(219,148,159,1) 0%, rgba(205,176,156,1) 49%, rgba(38,176,180,1) 100%)',
      textClass: '!text-paper',
    },
    spectrum5: {
      background:
        'linear-gradient(270deg, rgba(99,179,93,1) 0%, rgba(85,187,176,1) 49%, rgba(212,170,154,1) 100%)',
      textClass: '!text-paper',
    },
    spectrum6: {
      background: 'linear-gradient(90deg, #FFC8BA 0%, #BCB3FD 50%, #CA3E71 100%)',
      textClass: '!text-persistent-black',
    },
    spectrum7: {
      background: 'linear-gradient(90deg, #C2D5ED 0%, #37B3E5 50%, #000046 100%)',
      textClass: '!text-persistent-white',
    },
    spectrum8: {
      background: 'linear-gradient(90deg, #FFB548 0%, #730E12 50%, #340F12 100%)',
      textClass: '!text-persistent-white',
    },
    spectrum9: {
      background: 'linear-gradient(90deg, #B2DBD4 0%, #00B189 50%, #00303C 100%)',
      textClass: '!text-persistent-white',
    },
    white: {
      background: '#ffffff',
      textClass: '!text-persistent-black',
    },
    dark: {
      background: '#000000',
      textClass: '!text-persistent-white',
    },
    teal: {
      background: '#00B2A9',
    },
    violet: {
      background: '#8031A7',
    },
    blue: {
      background: '#407EC9',
    },
    yellow: {
      background: '#EAAA00',
    },

    // flex UI text-tile BG
    bg1: {
      background: '#00B2A9',
    },
    bg2: {
      background: '#E03C31',
    },
    bg3: {
      background: '#E782A9',
    },
    bg4: {
      background: '#EAAA00',
    },
    bg5: {
      background: '#407EC9',
    },
    bg6: {
      background: 'rgba(45, 41, 38, 1)',
    },
    bg7: {
      background: 'rgba(255, 255, 255, 1)',
    },
    bg8: {
      background: 'linear-gradient(90deg, #FFC8BA 0%, #BCB3FD 50%, #CA3E71 100%)',
    },
    bg9: {
      background: 'linear-gradient(90deg, #C2D5ED 0%, #37B3E5 50%, #000046 100%)',
    },
    bg10: {
      background: 'linear-gradient(90deg, #FFB548 0%, #730E12 50%, #340F12 100%)',
    },
    bg11: {
      background: 'linear-gradient(90deg, #B2DBD4 0%, #00B189 50%, #00303C 100%)',
    },
    // OG Page Header
    bg12: {
      background:
        'linear-gradient(90deg, rgb(77, 185, 100) 0%, rgb(231, 179, 80) 49%, rgb(236, 148, 157) 100%)',
    },
    bg13: {
      background:
        'linear-gradient(90deg, rgb(226, 83, 101) 0%, rgb(236, 108, 72) 49%, rgb(209, 144, 172) 100%)',
    },
    bg14: {
      background:
        'linear-gradient(90deg, rgb(189, 173, 215) 0%, rgb(135, 81, 159) 49%, rgb(177, 127, 157) 100%)',
    },
    bg15: {
      background:
        'linear-gradient(90deg, rgb(219, 148, 159) 0%, rgb(205, 176, 156) 49%, rgb(38, 176, 180) 100%)',
    },
    bg16: {
      background:
        'linear-gradient(270deg, rgb(99, 179, 93) 0%, rgb(85, 187, 176) 49%, rgb(212, 170, 154) 100%)',
    },
  },

  gradientBackground: `linear-gradient(145deg, rgba(207,174,168,0.3) 16%,
    rgba(139,188,188,0.3) 45%,
    rgba(100,193,195,0.3) 60%,
    rgba(73,184,161,0.3) 80%,
    rgba(80,178,104,0.3) 98%)`,
}

function isColorVisiblyLight(color) {
  let isLight = false
  try {
    isLight = getLuminance(color) > 0.179
  } catch (e) {}
  return isLight
}

export default Colors

export { withOpacity, isColorVisiblyLight }
