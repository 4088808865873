import PageData from '../../utils/PageData'
import IntlUtil from '../../utils/IntlUtil'
import { useRef, useState } from 'react'
import { useThemeUpdate } from '../../assets/styles/PortalThemeProvider'

function getClientLanguage() {
  let { language: browserLanguage } = navigator
  if (browserLanguage) browserLanguage = browserLanguage.slice(0, 2)

  const storedLanguage = window.localStorage.getItem('lang')

  return storedLanguage || browserLanguage
}

export const usePageDataFetch = (location, api, getComponentByName) => {
  const cancelPreviousPageFetch = useRef()
  const isAppFrameRequired = useRef(true)
  const [member, setMember] = useState()
  const [pageData, setPageData] = useState({})
  const [isPageLoaded, setIsPageLoaded] = useState(false)
  const [pageError, setPageError] = useState(null)
  const [routeKey, setRouteKey] = useState(0)
  const [contentKey, setContentKey] = useState(0)

  const { setDirection, updateThemeSettings } = useThemeUpdate()

  let { pathname: url, search, state: { isSilent } = {} } = location

  async function fetchPageData() {
    if (!isSilent) setIsPageLoaded(false)

    try {
      cancelPreviousPageFetch.current?.()

      if (search) search += `&cb=${Date.now()}`
      else search = `?cb=${Date.now()}`

      const { request, cancel } = api.connector.get(url + search, {
        headers: {
          'x-type': 'page',
          'x-appframe-required': isAppFrameRequired.current,
          'x-referer': encodeURI(
            decodeURI(
              `${window.location.pathname}${window.location.search || ''}${
                window.location.hash || ''
              }`,
            ),
          ),
          'x-language': getClientLanguage(),
        },
      })
      cancelPreviousPageFetch.current = cancel

      const newPageData = await request

      cancelPreviousPageFetch.current = null

      let { component: componentName } = newPageData

      let component = getComponentByName(componentName)
      if (component && component.import) {
        component = await component.import()
        component = component.default
      }

      newPageData.component = component
      newPageData.componentName = componentName

      let { appFrame, member: newMember, language } = newPageData
      let { appFrame: prevAppFrame } = pageData

      appFrame = newPageData.appFrame = appFrame || prevAppFrame
      newMember = newMember || member

      if (appFrame !== undefined) isAppFrameRequired.current = false

      let { settings: { labels, language: { currency = 'EUR' } = {}, languages } = {} } =
        appFrame || {}

      currency = currency.toUpperCase()

      language = language || getClientLanguage()

      if (newMember) {
        newMember.currency = currency
      }

      let locales = languages.map(({ locale }) => locale)
      let selectedLanguage =
        languages.find(({ locale }) => locale.slice(0, 2) === language) || languages[0]
      const { locale, rtl } = selectedLanguage

      let direction = rtl ? 'rtl' : 'ltr'

      PageData.appFrame = appFrame
      IntlUtil.labels = labels
      IntlUtil.supportedLocales = locales
      IntlUtil.locale = locale
      IntlUtil.currency = currency
      document.documentElement.lang = locale
      // make the language globally available for chatbot
      window.locale = locale

      setContentKey(contentKey + 1)
      if (!isSilent) setRouteKey(routeKey + 1)

      setDirection(direction)
      updateThemeSettings(appFrame?.settings?.general)
      setMember(newMember)

      newPageData.component && setPageData(newPageData)
      setPageError(null)
      setIsPageLoaded(true)

      // propagateRouteChange(pageData)
    } catch (error) {
      console.error('error:', error)

      window.scrollTo(0, 0)
      // if (!this.mounted) {
      //   return
      // }

      setPageError(error)
      setIsPageLoaded(true)
      // propagateRouteChange()
    }
  }

  return {
    fetchPageData,
    member,
    setMember,
    pageData,
    isPageLoaded,
    pageError,
    routeKey,
    contentKey,
  }
}

function getHashPathAndParams(hash) {
  if (!hash) {
    return { path: '', params: {} }
  }

  hash = hash.replace('#', '')

  let [path, params] = hash.split('?')
  params =
    (params &&
      params.split('&').reduce((data, pair) => {
        const [key, value] = pair.split('=')
        return { ...data, [key]: value }
      }, {})) ||
    {}

  return { path, params }
}

export function usePopupDataFetch(location, api, getComponentByName) {
  const cancelPreviousPageFetch = useRef()

  const [isPopupLoaded, setIsPopupLoaded] = useState(false)
  const [popupAsPage, setPopupAsPage] = useState(false)
  const [popupData, setPopupData] = useState(null)
  const [popupError, setPopupError] = useState(null)

  async function fetchPopupData() {
    let { hash } = location

    const { path, params } = getHashPathAndParams(hash)

    let isPage = params.page === 'true'

    let xType = isPage ? 'page' : 'popup'

    setIsPopupLoaded(false)

    try {
      cancelPreviousPageFetch.current?.()

      let search = `?cb=${Date.now()}`

      const { request, cancel } = api.connector.get(`/${path}${search}`, {
        headers: {
          'x-type': xType,
          'x-referer': encodeURI(
            decodeURI(
              `${window.location.pathname}${window.location.search || ''}${
                window.location.hash || ''
              }`,
            ),
          ),
        },
      })
      cancelPreviousPageFetch.current = cancel

      const popupData = await request

      cancelPreviousPageFetch.current = null

      let { component: componentName } = popupData

      let component = getComponentByName(componentName)
      if (component && component.import) {
        component = await component.import()
        component = component.default
      }

      popupData.component = component
      popupData.componentName = componentName
      popupData.props = {
        ...popupData.props,
        isPopUp: true,
      }

      setPopupAsPage(isPage)
      setPopupData(popupData)
      setPopupError(null)
      setIsPopupLoaded(true)
    } catch (error) {
      setPopupError(error)
      setIsPopupLoaded(true)
    }
  }

  return {
    isPopupLoaded,
    popupAsPage,
    popupData,
    popupError,
    fetchPopupData,
  }
}
