export const getDeviceType = () => {
  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    window.navigator.userAgent,
  )

  // iOS does not support window.screen.orientation
  const isMobileLandScape = isMobile && (window?.screen?.orientation?.angle > 0 || false)
  return {
    isMobile,
    isMobileLandScape,
  }
}
