import React from 'react'
import { Link as RouterLink } from 'react-router-dom'

export default function Link(props) {
  const { to = '', ...rest } = props

  if (!to || typeof to !== 'string') return <a {...rest} />

  if (/^https?:\/\//.test(to)) return <a href={to} {...rest} />

  return <RouterLink {...props} />
}
