import React from 'react'
import Colors from 'shared-ui/assets/styles/colors'
import classNames from 'classnames'

export const MAX_WIDTH = 1280

class PageModule extends React.Component {
  render() {
    const {
      className,
      children,
      size,
      boxShadow,
      variant,
      background = 'spectrum5',
      isPrint,
      backgroundColor,
      spacing = {},
      ...props
    } = this.props

    const { maxWidths = {} } = spacing
    const maxWidth = maxWidths[size] || MAX_WIDTH
    const sizeSpecificSpacing = spacing[size] || {}

    const { all: defaultAll } = spacing
    const { all = defaultAll } = sizeSpecificSpacing

    const {
      left: defaultLeft = all,
      right: defaultRight = all,
      top: defaultTop = all,
      bottom: defaultBottom = all,
    } = spacing
    const {
      left = defaultLeft,
      right = defaultRight,
      top = defaultTop,
      bottom = defaultBottom,
    } = sizeSpecificSpacing

    const spacingMode = backgroundColor || variant ? 'padding' : 'margin'

    const spacingStyles = {
      [spacingMode + 'Top']: top,
      [spacingMode + 'Bottom']: bottom,
      paddingLeft: left,
      paddingRight: right,
    }

    let backgroundSpectrum = backgroundColor
    let textClass

    if (variant === 'colored') {
      const selectedSpectrum = this.props.parent
        ? this.props.parent.background || background
        : background
      backgroundSpectrum =
        Colors.backgrounds[selectedSpectrum]?.background ||
        Colors.backgrounds['spectrum5'].background
      textClass =
        Colors.backgrounds[selectedSpectrum]?.textClass || Colors.backgrounds['spectrum5'].textClass
    }

    if (isPrint && backgroundSpectrum !== Colors.White) {
      return (
        <div className={className} style={{ ...spacingStyles, boxShadow }}>
          {React.Children.map(
            children,
            child =>
              child &&
              React.cloneElement(child, {
                ...props,
                isPrint,
                style: { maxWidth, marginLeft: 'auto', marginRight: 'auto' },
                size,
              }),
          )}
        </div>
      )
    }

    return (
      <div
        className={classNames(className, textClass)}
        style={{
          transition: 'background-color 500ms ease-in-out',
          background: backgroundSpectrum,
          ...spacingStyles,
          boxShadow,
        }}
      >
        {React.Children.map(
          children,
          child =>
            child &&
            React.cloneElement(child, {
              ...props,
              isPrint,
              style: { maxWidth, marginLeft: 'auto', marginRight: 'auto' },
              size,
            }),
        )}
      </div>
    )
  }
}

const asPageModule =
  spacing =>
  Component =>
  ({ children, ...rest }) => (
    <PageModule spacing={spacing} {...rest}>
      <Component>{children}</Component>
    </PageModule>
  )

export default PageModule
export { asPageModule }
