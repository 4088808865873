import React, { useEffect, useState } from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import Typography from '@material-ui/core/Typography'
import IntlUtil from 'shared-ui/utils/IntlUtil'
import HeaderItemTemplate from '~components/molecules/HeaderItem/template'
import { useWebsocket } from '~components/WebsocketEvents'
import useUpdateAnimation from '~src/hooks/useUpdateAnimation'
import { useMember } from 'shared-ui/components/pages/PageWrapper'

const styles = theme => ({
  root: {},
  points: {
    ...theme.typography.numeric,
    color: theme.palette.common.green,
    fontWeight: 'bold',
  },
})

function HeaderItemCash({ classes, ...props }) {
  const socket = useWebsocket()
  const member = useMember()

  const [cashBalance, setCashBalance] = useState(member?.cashBalance ?? 0)
  const style = useUpdateAnimation(cashBalance)

  useEffect(() => {
    const onUpdateBalance = newCashBalance => {
      setCashBalance(newCashBalance)
    }

    socket && socket.on('updateCashBalance', onUpdateBalance)

    return () => {
      socket && socket.off('updateCashBalance', onUpdateBalance)
    }
  }, [socket])

  return (
    <HeaderItemTemplate {...props}>
      <Typography variant="h6" style={style} className={classes.points}>
        {IntlUtil.price(cashBalance)}
      </Typography>
    </HeaderItemTemplate>
  )
}

export default withStyles(styles)(HeaderItemCash)
