import React from 'react'
import classNames from 'classnames'
import withStyles from '@material-ui/core/styles/withStyles'
import Modal from '@material-ui/core/Modal'
import Backdrop from '@material-ui/core/Backdrop'
import Fade from '@material-ui/core/Fade'

export const styles = ({ breakpoints, spacing: { unit } }) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 0,
    [breakpoints.up('sm')]: {
      padding: unit * 2,
    },
  },
  paper: {
    outline: 'none',
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    pointerEvents: 'none',
    '& > *': {
      pointerEvents: 'all',
    },
  },
})

class Popup extends React.Component {
  render() {
    const {
      BackdropProps,
      children,
      classes,
      className,
      disableBackdropClick,
      disableEscapeKeyDown,
      onClose,
      open,
      ...other
    } = this.props

    return (
      <Modal
        disableEnforceFocus
        className={classNames(classes.root, className)}
        BackdropComponent={Backdrop}
        BackdropProps={{
          ...BackdropProps,
        }}
        disableBackdropClick={disableBackdropClick}
        disableEscapeKeyDown={disableEscapeKeyDown}
        onClose={onClose}
        open={open}
        role="dialog"
        {...other}
      >
        <Fade appear in={open}>
          <div className={classes.paper}>{children}</div>
        </Fade>
      </Modal>
    )
  }
}

Popup.defaultProps = {
  disableBackdropClick: false,
  disableEscapeKeyDown: false,
}

export default withStyles(styles)(Popup)
