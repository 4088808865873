import React from 'react'
import classNames from 'classnames'
import withStyles from '@material-ui/core/styles/withStyles'
import { fade } from '@material-ui/core/styles/colorManipulator'
import HTMLParser from 'html-parse-stringify'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import { UNIT } from 'shared-ui/assets/styles/theme'
import { asPageModule } from '~components/templates/PageModule'
import { withApi } from 'shared-ui/api/ApiContext'
import { withRouter } from 'react-router-dom'
import TrackedVideo from '~components/atoms/TrackedVideo'

const sizes = {
  xs: 12,
  sm: 12,
  md: 12,
  lg: 12,
  xl: 12,
}

const columnsBySize = {
  xs: 12,
  sm: 12,
  md: 10,
  lg: 9,
  xl: 8,
}

// to calculate the size of the richTextModule, we calculate the width to get a percentage, but for big screens use the max width
const maxWidths = {
  xs: `${(columnsBySize.xs * 100) / 12}%`,
  sm: `${(columnsBySize.sm * 100) / 12}%`,
  md: `${(columnsBySize.md * 100) / 12}%`,
}

const createQuillStyles = () => {
  const lists = [
    'list-1',
    'list-2',
    'list-3',
    'list-4',
    'list-5',
    'list-6',
    'list-7',
    'list-8',
    'list-9',
  ]

  const padding = 1.5
  const bulletWidth = 1.2
  const bulletMargin = 0.3

  const styles = {
    '& p, ol, ul, pre, blockquote, h1, h2, h3, h4, h5, h6 ': {
      margin: 0,
      counterReset: lists.join(' '),
    },
    '& ul': {
      paddingLeft: `${padding}em`,
    },
    '& ol': {
      paddingLeft: 0,
    },
    '& ol > li': {
      listStyleType: 'none',
    },
    '& ul > li': {
      listStyleType: 'disc',
    },
    '& ul > li::before': {
      // content: '"\\2022"'
    },
    '& li::before': {
      display: 'inline-block',
      whiteSpace: 'nowrap',
      width: `${bulletWidth}em`,
      marginLeft: `-${padding}em`,
      marginRight: `${bulletMargin}em`,
      textAlign: 'right',
    },
    '& ol li': {
      counterReset: lists.join(' '),
      counterIncrement: 'list-0',
      paddingLeft: `${padding}em`,
      marginLeft: `${padding}em`,
    },
    '& ol li:before': {
      content: 'counter(list-0, decimal) ". "',
    },
  }

  lists.shift()

  const listStyles = ['decimal', 'lower-alpha', 'lower-roman']
  const numListStyles = listStyles.length

  for (let i = 1; i < 9; i++) {
    styles[`& ql-indent-${i}`] = {
      marginLeft: `${(i + 1) * padding}em`,
    }

    styles[`& li.ql-indent-${i}`] = {
      marginLeft: `${(i + 2) * padding}em`,
    }

    styles[`& ol li.ql-indent-${i}`] = {
      counterIncrement: `list-${i}`,
      counterReset: lists.join(' '),
    }

    styles[`& ol li.ql-indent-${i}:before`] = {
      content: `counter(list-${i}, ${listStyles[i % numListStyles]}) '. '`,
    }

    lists.shift()
  }

  return styles
}

function getStylesFromSizes(sizes) {
  return Object.keys(sizes).reduce(
    (reducesSizes, key) => ({ ...reducesSizes, [key]: { width: `${(100 / 12) * sizes[key]}%` } }),
    {},
  )
}

const styles = ({ typography: { body2 }, palette, spacing: { unit } }) => {
  const gridPadding = {
    paddingLeft: unit * 2,
    paddingRight: unit * 2,
    boxSizing: 'border-box',
  }

  return {
    root: body2,
    gridPadding,

    containerPadding: {
      ...gridPadding,
    },
    container: {
      ...createQuillStyles(),
      '& > p > * + *': {
        marginTop: unit,
      },
      '& * + h1': {
        marginTop: unit * 2,
      },
      '& h1': {
        marginBottom: unit,
      },
      '& p:not(:last-child),ul:not(:last-child),ol:not(:last-child),blockquote:not(:last-child)': {
        marginTop: 0,
        marginBottom: '1.5em',
      },
    },
    ul: {
      paddingLeft: '2.5em',
    },
    ol: {
      paddingLeft: '2.5em',
    },
    gridItem: {
      marginLeft: 'auto !important',
      marginRight: 'auto !important',
      boxSizing: 'border-box',
    },
    pre: {
      padding: `${unit / 2}px ${unit}px`,
      backgroundColor: fade(palette.primary.main, 0.1),
      borderRadius: unit / 4,
    },
    image4to3: {
      width: '100%',
      paddingTop: '75%',
      backgroundSize: 'cover',
    },
    imageContainer: {
      '&:not(:last-child)': {
        marginBottom: unit * 2,
      },
    },
    image: {
      width: '100%',
      display: 'block',
    },
    image16to9: {
      width: '100%',
      paddingTop: '56.25%',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundSize: 'contain',
    },
    blockquote: {
      fontStyle: 'italic',
      paddingLeft: unit,
      border: `0 solid ${palette.primary.main}`,
      borderLeftWidth: unit / 4,
    },
    ...getStylesFromSizes(sizes),
  }
}

class RichTextComponent extends React.Component {
  constructor(props) {
    super(props)

    const { horizontalPadding = UNIT } = props

    this.horizontalPadding = horizontalPadding
  }

  renderContent = () => {
    let { content, classes, size = 'xs', color } = this.props
    const imageClasses = classNames(classes.imageContainer)
    const imageStyle = {
      marginLeft: this.horizontalPadding,
      marginRight: this.horizontalPadding,
    }

    if (!content) {
      return null
    }

    // quill doesn't support soft returns, therefore we treat singular returns as a break insinde a paragraph
    // and a double returns as a new paragraph
    const hash = '%%%%%%%%'
    content = content.replace(/'/g, '&apos;')
    content = content.replace(/<p><br><\/p>(?!\s*?<p><br><\/p>)/gi, hash)
    content = content.replace(/<\/div><\/p><p>/gi, '</div>')
    content = content.replace(/<\/p><p>/gi, '<br>')
    content = content.replace(new RegExp(hash, 'g'), '')
    content = content.replace('<p><br></p>', '<br>')
    // Preserve the whitespaces between tags
    const SPACE_ENCLOSED = /(>)(\s+)(<\/?[a-z]+)/gi
    content = content.replace(SPACE_ENCLOSED, (matches, begin, spaces, end) => {
      return `${begin}<span>${spaces}</span>${end}`
    })

    const parsedContent = HTMLParser.parse(`<div>${content}</div>`)
    const defaultGridProps = {
      className: classNames(classes.gridItem, classes.gridPadding),
    }

    const parseAttr = attr => {
      if (!attr) {
        return undefined
      }

      let { class: className, style, ...rest } = attr

      // Hack to remove the color from style and check if we can replace the style to class in quill.js
      let result = /^color:\s?rgb\(\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d{1,3})\s*\);?$/.exec(style)
      // const hex = /^#([0-9a-f]{3}|[0-9a-f]{6})$/.exec(style)

      if (result) {
        style = { color: `rgb(${result[1]}, ${result[2]}, ${result[3]})` }
      } else {
        style = undefined
      }

      return { className, style, ...rest }
    }

    const renderTypography = (children, attrs, variant, index) => {
      const attributes = parseAttr(attrs)
      const { className, ...restAttributes } = attributes
      return (
        <Typography
          key={index}
          {...restAttributes}
          variant={variant}
          style={{
            color,
            paddingLeft: this.horizontalPadding,
            paddingRight: this.horizontalPadding,
          }}
          className={classNames(classes.gridItem, className, classes[size])}
        >
          {parse(children)}
        </Typography>
      )
    }

    const onClickHandler = async (e, sitemapid, target, api, href, history) => {
      e.preventDefault()
      if (sitemapid) {
        try {
          const { request } = api.linkReference({
            _id: sitemapid,
          })

          let response = await request
          let { slug } = response
          if (target === 'popup') {
            if (/^http/.test(slug)) {
              window.open(slug)
              return
            }
            let currentPath = history.location.pathname
            if (currentPath.charAt(currentPath.length - 1) === '/')
              currentPath = currentPath.slice(0, -1)

            if (slug.charAt(0) === '/' && slug.charAt(1) !== '#')
              slug = `#${slug.slice(1)}?page=true`

            history.push(currentPath + slug)
            return
          } else if (target === 'newtab') {
            window.open(slug)
            return
          } else if (target === '_self') {
            /^http/.test(slug) ? window.location.href = slug : history.push(slug)
            return
          } else if (/^\/#/.test(slug)) {
            slug = slug.slice(1)
            history.push(slug)
          } else if (/^http/.test(slug)) {
            window.location = slug
            return
          }
        } catch (e) {
          console.log('Error while open RichTextHandler')
        }

        return
      }

      window.open(href)
    }

    const renderButton = (children, attrs, variant, index) => {
      const { api, history } = this.props
      const attributes = parseAttr(attrs)
      const { href, sitemapid, target = '_self' } = attributes
      return (
        <Button
          onClick={e => onClickHandler(e, sitemapid, target, api, href, history)}
          to={href}
          key={index}
          variant={'contained'}
        >
          {parse(children)}
        </Button>
      )
    }

    const renderAnchor = (children, attrs, Tag, props) => {
      const { api, history } = this.props
      const attributes = parseAttr(attrs)
      const { className, href, sitemapid, target = '_self', ...restAttributes } = attributes
      return (
        <Tag
          {...restAttributes}
          href={'#'}
          onClick={e => onClickHandler(e, sitemapid, target, api, href, history, restAttributes)}
        >
          {parse(children)}
        </Tag>
      )
    }

    const renderTag = (children, attrs, Tag, props) => {
      return (
        <Tag {...defaultGridProps} {...parseAttr(attrs)} {...props}>
          {parse(children)}
        </Tag>
      )
    }
    const findAutoPlayValue = autoplayVal => {
      if (autoplayVal == 'true') return 1
      else return false
    }
    const parse = children => {
      return children.map((contentItem, index) => {
        if (!contentItem) {
          return null
        }

        const { type, name, content, children: grandChildren, attrs, voidElement } = contentItem

        let Component

        switch (type) {
          case 'text':
            return <span key={index} dangerouslySetInnerHTML={{ __html: content }} />
          case 'tag': {
            switch (name) {
              case 'a':
                return renderAnchor(grandChildren, attrs, name, {
                  key: index,
                  className: classNames(classes.gridItem, classes[name], classes[size]),
                })
              case 'h1':
                return renderTypography(grandChildren, attrs, 'h3', index)
              case 'h2':
                return renderTypography(grandChildren, attrs, 'h4', index)
              case 'h3':
                return renderTypography(grandChildren, attrs, 'h5', index)
              case 'h4':
                return renderTypography(grandChildren, attrs, 'h6', index)
              case 'p':
                // if (grandChildren && grandChildren.length === 1 && grandChildren[0].name === 'br') { return <br/> }
                return renderTypography(grandChildren, attrs, 'body2', index)
              case 'ol':
              case 'ul':
              case 'pre':
              case 'blockquote':
                return renderTag(grandChildren, attrs, name, {
                  key: index,
                  className: classNames(classes.gridItem, classes[name], classes[size]),
                })
              case 'video':
                const { src, alt, autoplay } = attrs

                return (
                  <div key={index} className={imageClasses} style={imageStyle}>
                    <TrackedVideo
                      controls
                      muted={autoplay}
                      autoplay={findAutoPlayValue(autoplay)}
                      className={classes.image}
                      src={src}
                      name={alt}
                    >
                      {parse(grandChildren)}
                    </TrackedVideo>
                  </div>
                )
              case 'button':
                return renderButton(grandChildren, attrs, name, {
                  key: index,
                  className: classNames(classes.gridItem, classes[name]),
                })
              case 'img':
                const groupedImages = []
                let sibling = contentItem
                let siblingIndex = index

                while (true) {
                  groupedImages.push(sibling)

                  if (groupedImages.length === 2) {
                    break
                  }

                  siblingIndex++
                  sibling = children.length > siblingIndex && children[siblingIndex]

                  const { name: siblingName } = sibling

                  if (siblingName !== 'img') {
                    break
                  }

                  children[siblingIndex] = null
                }

                if (groupedImages.length === 1) {
                  const { src } = attrs

                  return (
                    <div key={index} className={imageClasses} style={imageStyle}>
                      <img className={classes.image} src={src} alt="" />
                    </div>
                  )
                }

                return (
                  <Grid key={index} container xs={12}>
                    {groupedImages.map(image => {
                      const { attrs: { src } = {} } = image
                      return (
                        <Grid item xs={6} className={classes.gridItem}>
                          <div
                            className={classes.image4to3}
                            style={{ backgroundImage: `url(${src})` }}
                          />
                        </Grid>
                      )
                    })}
                  </Grid>
                )

              case 'br':
                return <br key={index} />

              default:
                Component = name
            }
            break
          }
          default: {
            break
          }
        }

        if (voidElement) {
          return <Component key={index} {...parseAttr(attrs)} />
        }

        return (
          <Component key={index} {...parseAttr(attrs)}>
            {parse(grandChildren)}
          </Component>
        )
      })
    }

    return (
      <div className={classes.root} style={{ color }}>
        {parse(parsedContent)}
      </div>
    )
  }

  render() {
    const { className, classes, style = {}, bottomChildren } = this.props

    return (
      <div
        className={classNames(classes.container, className)}
        style={{
          ...style,
          paddingLeft: this.horizontalPadding,
          paddingRight: this.horizontalPadding,
          boxSizing: 'border-box',
          width: '100%',
        }}
      >
        {this.renderContent()}
        {bottomChildren}
      </div>
    )
  }
}

const RichText = withStyles(styles)(withApi(withRouter(RichTextComponent)))
const RichTextModule = asPageModule({
  top: UNIT * 4,
  bottom: UNIT * 4,
  maxWidths,
})(RichText)

export default RichTextModule
export { RichText, maxWidths }
