import { useEffect, useRef, useState } from 'react'

export function useUltimateMachine(value, delay) {
  const [on, setOn] = useState()

  let first = useRef(true)

  useEffect(() => {
    if (first.current) {
      first.current = false
      return
    }

    setOn(true)

    let timeoutId = setTimeout(() => {
      setOn(false)
    }, delay)

    return () => {
      clearTimeout(timeoutId)
    }
  }, [value, delay])

  return on
}

export default function useUpdateAnimation(
  value,
  { duration = 0.5, scale = 1.3, rotation = 5 } = {},
) {
  const isOn = useUltimateMachine(value, duration * 250)

  return {
    transition: `transform ${duration}s cubic-bezier(0.175, 0.885, 0.32, 1.275)`,
    transform: isOn ? `scale(${scale}, ${scale}) rotate(${rotation}deg)` : 'scale(1.0, 1.0)',
  }
}
