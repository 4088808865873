import React from 'react'
import { usePageData } from 'shared-ui/components/pages/PageWrapper'

const resetLineHeight = `* {
  line-height: normal !important;
}`

const resetLetterSpacing = `* {
  letter-spacing: 0 !important;
}`

const fixesByLanguage = {
  km: [resetLineHeight, resetLetterSpacing],
  my: [resetLetterSpacing],
  ar: [resetLetterSpacing],
  ko: [resetLetterSpacing],
  fa: [resetLetterSpacing],
  ur: [resetLetterSpacing],
  bn: [resetLetterSpacing],
  ml: [resetLetterSpacing],
}

export default function IntlStyleFixes() {
  const pageData = usePageData()

  if (!pageData) return

  const { language } = pageData

  const fixes = fixesByLanguage[language]

  if (fixes && fixes.length) {
    return fixes.map((fix, i) => <style key={i.toString()}>{fix}</style>)
  }

  return null
}
