import useEvent from 'react-use-event-hook'
import { useApi } from 'shared-ui/api/ApiContext'
import useEffectOnce from 'shared-ui/hooks/useEffectOnce'
import { useAppFrame } from './AppFrameContext'

export default function ReCaptcha() {
  const appFrame = useAppFrame()
  const api = useApi()

  const technical = appFrame?.settings?.technical
  const captchaId = technical?.captchaId
  const captchaVisibility = technical?.captchaVisibility

  const initCaptchaEvent = useEvent(async () => {
    api.connector.setupCaptcha(captchaId, captchaVisibility)
  })

  useEffectOnce(() => {
    if (!captchaId) return false
    void initCaptchaEvent()
  }, [captchaId, initCaptchaEvent])

  return null
}
