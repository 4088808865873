import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import withWidth from '@material-ui/core/withWidth'
import classNames from 'classnames'
import PageProgress from 'shared-ui/components/PageProgress'
import Paper from '@material-ui/core/Paper'
import { withPopup } from 'shared-ui/components/popup/PopupContext'
import { UNIT } from '../../assets/styles/theme'

const styles = ({ palette, breakpoints, spacing: { unit } }) => ({
  root: {
    maxWidth: 688,
    maxHeight: 680,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    position: 'relative',
    backgroundColor: palette.paper,
    borderRadius: 'var(--radius-outer)',
    width: '100%',
    height: '100%',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    [breakpoints.down('xs')]: {
      maxWidth: '100vw',
      maxHeight: '100vh',
    },
  },
  fullWidhMobile: {
    [breakpoints.down('xs')]: {
      maxWidth: '100vw',
      maxHeight: '100vh',
    },
  },
  progress: {
    position: 'absolute',
    zIndex: 9999,
    width: '100%',
    opacity: 0.5,
  },
})

export default withPopup(
  withWidth()(
    withStyles(styles, { name: 'PopupBox' })(
      class PopupBox extends React.Component {
        state = { options: {} }

        componentDidMount() {
          const { listenToOuterClose } = this.props

          if (listenToOuterClose) this.subscription = listenToOuterClose(this.handleCloseClick)
        }

        componentWillUnmount() {
          this.subscription && this.subscription.unsubscribe()
        }

        handleCloseClick = event => {
          const { onClose } = this.props
          onClose && onClose(event)
        }

        render() {
          const {
            width,
            children: childrenProp,
            classes,
            className,
            isLoading,
            minWidth,
            maxWidth,
            minHeight,
            maxHeight,
            hideTitle,
            fullWidhMobile,
            picture,
            borderColor,
          } = this.props

          let style
          if (width !== 'xs') {
            style = {}
            if (typeof minWidth === 'string' || minWidth > 0) style.minWidth = minWidth
            if (typeof maxWidth === 'string' || maxWidth > 0) style.maxWidth = maxWidth
            if (typeof minHeight === 'string' || minHeight > 0) style.minHeight = minHeight
            if (typeof maxHeight === 'string' || maxHeight > 0) style.maxHeight = maxHeight
          }

          const children = React.Children.map(childrenProp, child => {
            if (!child) {
              return null
            }

            let props = { hideTitle }
            if (!child.props.onClose) props.onClose = this.handleCloseClick

            return React.cloneElement(child, props)
          })
          const backgroundStyle = picture ? { backgroundImage: `url(${picture.path})` } : {}
          const borderStyle =
            borderColor === undefined || borderColor === '#ffffff'
              ? {}
              : { border: `solid ${UNIT - 4}px ${borderColor}` }

          return (
            <Paper
              square
              className={classNames(
                classes.root,
                { [classes.fullWidhMobile]: fullWidhMobile },
                className,
              )}
              style={{
                ...style,
                ...backgroundStyle,
                ...borderStyle,
              }}
            >
              <PageProgress visible={isLoading} className={classes.progress} />
              {children}
            </Paper>
          )
        }
      },
    ),
  ),
)
