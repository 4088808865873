import PortalLabes from '~src/constants/PortalLabels'

const getAmountByType = (points = [], type = 'default') => {
  const found = points.find(({ type: t }) => t === type)
  return (found && found.amount) || 0
}

const getPointsLabel = (type = 'default') =>
  `${PortalLabes.J_POINTS}${type === 'default' ? '' : `_${type.toUpperCase()}`}`

const getColorLevelByType = (points, type) => {
  const point = Array.isArray(points) && points.find(({ type: pointsType }) => pointsType === type)
  const { color } = point || {}
  return color ?? 'var(--accent-400)'
}

const getColorByType = (points, type = 'default') => {
  return getColorLevelByType(points, type)
}

export { getAmountByType, getPointsLabel, getColorByType, getColorLevelByType }
