import React from 'react'

const PopupContext = React.createContext(false)
const { Consumer: PopupConsumer } = PopupContext

const withPopup = Component => props => (
  <PopupConsumer>{value => <Component {...props} {...value} />}</PopupConsumer>
)

export { withPopup }

export default PopupContext
