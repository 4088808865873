import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import Typography from '@material-ui/core/Typography'
import HeaderItem from '~components/molecules/HeaderItem'

import classNames from 'classnames'
import { Hidden } from '@material-ui/core'

const styles = ({ palette }) => ({
  root: {
    position: 'relative',
  },
  badge: {
    backgroundColor: palette.primary.main,
    borderRadius: 5,
    height: 10,
    width: 10,
    position: 'absolute',
    left: '50%',
    top: '50%',
    marginLeft: 3,
    marginTop: -8,
  },
})

class HeaderItemNotificationCenter extends React.Component {
  render() {
    const {
      classes,
      member: { hasNotifications },
      ...props
    } = this.props

    return (
      <Hidden xsDown>
        <div className={classes.root}>
          <HeaderItem {...props} />
          {hasNotifications && <Typography className={classNames(classes.badge)} />}
        </div>
      </Hidden>
    )
  }
}

export default withStyles(styles)(HeaderItemNotificationCenter)
