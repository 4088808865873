import { useEffect, useRef } from 'react'

export default function useEffectOnce(effect, deps = []) {
  const ranEffect = useRef(false)

  useEffect(
    (...args) => {
      if (ranEffect.current) return

      const res = effect(...args)
      if (res === false) return

      ranEffect.current = true
    },
    [...deps, effect],
  )
}
