export const createPalette = () => {
  const style = getComputedStyle(document.documentElement)

  const accent = {
    accent100: {
      main: style.getPropertyValue('--accent-100'),
      contrastText: style.getPropertyValue('--contrast-100'),
    },
    accent200: {
      main: style.getPropertyValue('--accent-200'),
      contrastText: style.getPropertyValue('--contrast-200'),
    },
    accent300: {
      main: style.getPropertyValue('--accent-300'),
      contrastText: style.getPropertyValue('--contrast-300'),
    },
    accent400: {
      main: style.getPropertyValue('--accent-400'),
      contrastText: style.getPropertyValue('--contrast-400'),
    },
    accent500: {
      main: style.getPropertyValue('--accent-500'),
      contrastText: style.getPropertyValue('--contrast-500'),
    },
  }

  const grey = {
    grey100: style.getPropertyValue('--grey-100'),
    grey200: style.getPropertyValue('--grey-200'),
    grey300: style.getPropertyValue('--grey-300'),
    grey400: style.getPropertyValue('--grey-400'),
    grey500: style.getPropertyValue('--grey-500'),
  }

  const common = {
    red: style.getPropertyValue('--red'),
    orange: style.getPropertyValue('--orange'),
    blue: style.getPropertyValue('--blue'),
    green: style.getPropertyValue('--green'),

    persistentWhite: style.getPropertyValue('--persistent-white'),
    persistentBlack: style.getPropertyValue('--persistent-black'),
  }

  const paper = style.getPropertyValue('--paper')

  return {
    ...accent,
    ...grey,

    paper,

    common,

    secondary: {
      main: grey.grey500,
      contrastText: paper,
    },
    primary: {
      main: accent.accent400.main,
      contrastText: accent.accent400.contrastText,
    },

    error: {
      main: common.red,
    },

    action: {
      selected: grey.grey500,
      active: grey.grey400,
    },

    background: {
      paper: style.getPropertyValue('--paper'),
    },

    text: {
      primary: grey.grey500,
      secondary: grey.grey500,
    },
  }
}
