import React, { Component } from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import { ErrorPage } from 'shared-ui/components/pages/ErrorPage'
import IntlUtil from 'shared-ui/utils/IntlUtil'
import ConnexusLabels from 'shared-ui/constants/ConnexusLabels'
import ReactGA from 'react-ga'

const styles = ({ breakpoints, spacing: { unit } }) => ({})

class ErrorHandler extends Component {
  constructor(props) {
    super(props)

    this.state = {
      error: '',
      errorInfo: {},
    }
  }

  componentDidCatch(error, errorInfo) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error: error,
      errorInfo: errorInfo,
    })

    ReactGA.exception({
      description: `${error.toString()} \n ${errorInfo.componentStack}`,
      fatal: true,
    })
  }

  render() {
    if (this.state.errorInfo.componentStack) {
      console.error(`${this.state.error.toString()} \n ${this.state.errorInfo.componentStack}`)

      return (
        <div style={{ flex: 1 }}>
          <ErrorPage message={IntlUtil.label(ConnexusLabels.MSG_ERROR)} />
        </div>
      )
    }
    // Normally, just render children
    return this.props.children
  }
}

export default withStyles(styles)(ErrorHandler)
