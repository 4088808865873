import React from 'react'
import Popup from 'shared-ui/components/organisms/Popup/index'
import PopupBox from './PopupBox'
import PopupContent from './PopupContent'
import PopupHeader from './PopupHeader'
import Subscriber from 'shared-ui/utils/Subscriber'
import PopupContext from './PopupContext'

class PopupWrapper extends React.Component {
  onCloseSubscriber = new Subscriber()
  contextValue = { popup: true, listenToOuterClose: this.onCloseSubscriber.subscribe }

  handlePopupClose = event => {
    this.onCloseSubscriber.dispatch(event)
  }

  componentWillUnmount() {
    this.onCloseSubscriber.reset()
  }

  render() {
    const {
      wrap,
      onClose,
      children,
      isOpen,
      preventCickOutside,
      componentName,
      /*minWidth,*/ maxWidth,
      /*minHeight,*/ maxHeight,
    } = this.props

    return wrap ? (
      <Popup data-popup-id={componentName} onClose={onClose} open={isOpen}>
        <PopupBox onClose={onClose} maxWidth={maxWidth} maxHeight={maxHeight}>
          <PopupHeader />
          <PopupContent>
            <PopupContext.Provider value={this.contextValue}>{children}</PopupContext.Provider>
          </PopupContent>
        </PopupBox>
      </Popup>
    ) : (
      <Popup
        data-popup-id={componentName}
        disableBackdropClick={preventCickOutside}
        disableEscapeKeyDown={preventCickOutside}
        onClose={this.handlePopupClose}
        open={isOpen}
      >
        <PopupContext.Provider value={this.contextValue}>{children}</PopupContext.Provider>
      </Popup>
    )
  }
}

export default PopupWrapper
