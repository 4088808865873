import React from 'react'
import Button from '@material-ui/core/Button'
import withStyles from '@material-ui/core/styles/withStyles'
import Link from 'shared-ui/components/Link'

const styles = ({ palette, spacing: { unit } }) => ({
  a: {
    textDecoration: 'none',
  },
  caption: {
    color: palette.common.green,
    fontSize: '0.79375rem',
  },
})

export default withStyles(styles)(({ className = '', label, classes, to }) => (
  <Link to={to || '#'}>
    <Button size="small" className={`${classes.caption} ${className}`} to={to}>
      {label}
    </Button>
  </Link>
))
