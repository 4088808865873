import React, { createContext, useContext, useEffect, useRef } from 'react'
import { ErrorPage } from './ErrorPage'
import { usePageDataFetch, usePopupDataFetch } from './usePageDataFetch'
import { useApi } from '../../api/ApiContext'

const defaultRenderStatusCode = props => {
  return <ErrorPage {...props} />
}

const PageDataContext = createContext({})
export const usePageData = () => useContext(PageDataContext)
const PopupDataContext = createContext({})
export const usePopupData = () => useContext(PopupDataContext)
const MemberContext = createContext({})
export const useMember = () => useContext(MemberContext)
const UpdateMemberContext = createContext(undefined)
export const useUpdateMember = () => useContext(UpdateMemberContext)

export function isPrintMode() {
  let xType = new URLSearchParams(window.location.search).get('xType')
  return xType === 'print'
}

export default function PageWrapper(props) {
  let {
    AppFrame,
    PopupWrapper,
    getComponentByName,
    renderStatusCode = defaultRenderStatusCode,
    history,
    location,
    location: { hash, pathname, state = {} },
  } = props

  const api = useApi()

  const {
    fetchPageData,
    member,
    setMember,
    pageData,
    isPageLoaded,
    pageError,
    routeKey,
    contentKey,
  } = usePageDataFetch(location, api, getComponentByName)

  const { fetchPopupData, popupData, isPopupLoaded, popupError, popupAsPage } = usePopupDataFetch(
    location,
    api,
    getComponentByName,
  )

  const { action } = history
  const actionRef = useRef(action)
  actionRef.current = action

  if (pathname.endsWith('/')) pathname = pathname.slice(0, -1)

  useEffect(() => {
    const isPseudo = state && state.pseudoRoute !== undefined

    if (!isPseudo || actionRef.current === 'POP') {
      void fetchPageData()
    }
  }, [pathname, state.code])

  useEffect(() => {
    if (hash && hash.length) {
      void fetchPopupData()
    }
  }, [hash])

  function renderPageContent() {
    if (!isPageLoaded) return null

    const {
      component: Component,
      props: pageProps = {},
      appFrame: { canUpdateVersion, settings } = {},
    } = pageData

    const { theme, ...rest } = pageProps

    if (pageError) {
      if (pageError.response?.data?.navigateTo) return null

      const status = pageError.response?.status
      const title = pageError.response?.data?.error?.title
      const message = pageError.response?.data?.error?.message

      return renderStatusCode({ status, title, message })
    }

    return (
      <Component
        {...props}
        {...rest}
        linkTheme={theme}
        member={member}
        settings={settings}
        canUpdateVersion={canUpdateVersion}
        isPrint={isPrintMode()}
        api={api}
      />
    )
  }

  function handlePopupClose() {
    history.push(location.pathname + location.search)
  }

  function renderPopup() {
    if (!PopupWrapper) return null

    if (!popupData) return null

    const { component, componentName, props: popupProps = {} } = popupData

    const { theme, ...rest } = popupProps

    const isOpen = Boolean(hash && hash.length > 0)

    if (popupError) {
      const {
        response: { status, data: { navigateTo, error: { title, message } = {} } = {} } = {},
      } = popupError

      if (navigateTo) return null

      return (
        <PopupWrapper wrap isOpen={isOpen} onClose={handlePopupClose}>
          {renderStatusCode({ status, title, message })}
        </PopupWrapper>
      )
    }

    if (!component) return null

    const Component = isPageLoaded && isPopupLoaded && component

    return (
      <PageDataContext.Provider value={popupData}>
        <PopupWrapper
          componentName={Component ? componentName : undefined}
          wrap={popupAsPage}
          isOpen={isOpen}
          isLoading={Boolean(Component)}
          onClose={handlePopupClose}
          preventCickOutside={popupProps && popupProps.preventCickOutside}
          api={api}
        >
          {Component && (
            <Component {...props} {...rest} api={api} member={member} onClose={handlePopupClose} />
          )}
        </PopupWrapper>
      </PageDataContext.Provider>
    )
  }

  return (
    <PageDataContext.Provider value={pageData}>
      <PopupDataContext.Provider value={popupData}>
        <MemberContext.Provider value={member}>
          <UpdateMemberContext.Provider value={setMember}>
            <AppFrame busy={!isPageLoaded} routeKey={routeKey} contentKey={contentKey}>
              {renderPopup()}
              {renderPageContent()}
            </AppFrame>
          </UpdateMemberContext.Provider>
        </MemberContext.Provider>
      </PopupDataContext.Provider>
    </PageDataContext.Provider>
  )
}
