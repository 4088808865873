import React from 'react'
import classNames from 'classnames'
import { RichText } from '~components/molecules/RichTextModule'
import { useAppFrame } from 'shared-ui/components/AppFrameContext'

const HealthWarning = ({ className }) => {
  const appFrame = useAppFrame()

  const {
    displayHealthWarning,
    healthWarningDesktop,
    healthWarningMobile,
    healthWarningSize,
    healthWarningFooter,
    healthWarningText,
    healthWarningTextContent,
    healthWarningTextDarkBackground,
  } = appFrame.settings.general

  if (!displayHealthWarning) return null

  let { path: pathDesktop } = healthWarningDesktop || {}
  let { path: pathMobile } = healthWarningMobile || {}

  if (!healthWarningText && !pathDesktop && !pathMobile) return null

  if (!pathDesktop) pathDesktop = pathMobile
  else if (!pathMobile) pathMobile = pathDesktop

  const footerClass = classNames({
    'fixed bottom-0 w-full': healthWarningFooter,
  })

  return (
    <div
      className={classNames(
        '@container border-grey-200 bg-paper flex items-center overflow-hidden whitespace-nowrap border-t ',
        {
          '!bg-persistent-white': !healthWarningText,
          '!bg-grey-500': healthWarningText && healthWarningTextDarkBackground,
          '!bg-paper': healthWarningText && !healthWarningTextDarkBackground,
        },
        className,
        footerClass,
      )}
      style={{ height: `${healthWarningSize}vh` }}
    >
      {healthWarningText ? (
        <RichText
          content={healthWarningTextContent}
          className={classNames('h-auto whitespace-normal break-words', {
            '[&_*]:!text-paper': healthWarningTextDarkBackground,
          })}
        />
      ) : (
        <>
          <img
            src={pathDesktop}
            alt="health warning"
            className={classNames('hidden h-full w-full object-contain sm:block')}
          />
          <img
            src={pathMobile}
            alt="health warning"
            className={classNames('h-full w-full object-contain sm:hidden')}
          />
        </>
      )}
    </div>
  )
}

export default HealthWarning
