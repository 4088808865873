import React from 'react'
import LinearProgress from '@material-ui/core/LinearProgress'
import Fade from '@material-ui/core/Fade'

class PageProgress extends React.Component {
  static defaultProps = {
    visible: false,
    fadeOutDelay: 300,
  }

  constructor(props) {
    super(props)

    this.state = {
      loaderVisible: props.visible,
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.visible !== this.props.visible) {
      clearTimeout(this.loaderTimeout)

      if (this.props.visible) {
        this.setState({ loaderVisible: true })
      } else {
        this.loaderTimeout = setTimeout(() => {
          this.setState({ loaderVisible: false })
        }, this.props.fadeOutDelay)
      }
    }
  }

  render() {
    const { className = '', style } = this.props
    const { loaderVisible } = this.state

    return (
      <Fade in={loaderVisible} unmountOnExit timeout={500} className={className} style={style}>
        <LinearProgress color={'secondary'} />
        {/*<div></div>*/}
      </Fade>
    )
  }
}

export default PageProgress
