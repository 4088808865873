import React, { useContext, useMemo, useState } from 'react'
import { createTheme } from './theme'
import createGenerateClassName from '@material-ui/core/styles/createGenerateClassName'
import jssPreset from '@material-ui/core/styles/jssPreset'
import { create } from 'jss'
import rtl from 'jss-rtl'
import JssProvider from 'react-jss/lib/JssProvider'
import createMuiTheme from '@material-ui/core/styles/createMuiTheme'
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider'
import useEvent from 'react-use-event-hook'

const jss = create({ plugins: [...jssPreset().plugins, rtl()] })
const generateClassName = createGenerateClassName()

const PortalThemeUpdaterContext = React.createContext()

const supportedAccentColors = ['teal', 'violet', 'blue', 'yellow']

export default function PortalThemeProvider({ children, lightSchemeOnly }) {
  const [direction, setDirection] = useState('ltr')
  const [fontName, setFontName] = useState('roboto')
  let [accentColor, setAccentColor] = useState('teal')
  let [corners, setCorners] = useState('edgy')

  accentColor = supportedAccentColors.includes(accentColor) ? accentColor : 'teal'

  const [colorScheme, setColorScheme] = useState(() => {
    if (lightSchemeOnly) return 'light'

    if (localStorage.colorScheme === 'dark') return 'dark'
    if (localStorage.colorScheme === 'light') return 'light'
    if (window.matchMedia('(prefers-color-scheme: dark)').matches) return 'dark'

    return 'light'
  })

  function updateAndStoreColorScheme(scheme) {
    setColorScheme(scheme)
    localStorage.setItem('colorScheme', scheme)
  }

  const theme = useMemo(() => {
    if (lightSchemeOnly) {
      document.documentElement.dataset.accent = 'teal'
      document.documentElement.dataset.font = `font-roboto`
    } else {
      document.body.dir = direction
      document.documentElement.dataset.accent = accentColor
      document.documentElement.dataset.corners = corners
      document.documentElement.dataset.font = `font-${fontName}`
      document.documentElement.dataset.scheme = colorScheme
    }

    return createMuiTheme(createTheme({ direction, fontName }))
  }, [direction, fontName, accentColor, colorScheme, lightSchemeOnly, corners])

  const updateThemeSettings = useEvent(generalSettings => {
    if (generalSettings?.writingSystem) {
      const fontField = `${generalSettings.writingSystem}Font`
      const fontValue = generalSettings?.[fontField]

      let font = fontValue?.toLowerCase().replace(/\s/g, '-')
      if (font) setFontName(font)
    }

    if (generalSettings?.accentColor) setAccentColor(generalSettings.accentColor)
    if (generalSettings?.corners) setCorners(generalSettings?.corners)
  })

  return (
    <JssProvider jss={jss} generateClassName={generateClassName}>
      <MuiThemeProvider theme={theme}>
        <PortalThemeUpdaterContext.Provider
          value={{
            direction,
            setDirection,
            updateThemeSettings,
            colorScheme,
            setColorScheme: updateAndStoreColorScheme,
          }}
        >
          {children}
        </PortalThemeUpdaterContext.Provider>
      </MuiThemeProvider>
    </JssProvider>
  )
}

export const useThemeUpdate = () => useContext(PortalThemeUpdaterContext)
