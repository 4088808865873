import React, { useContext } from 'react'

const AppFrameContext = React.createContext({})

const { Provider: AppFrameProvider, Consumer: AppFrameConsumer } = AppFrameContext

export const useAppFrame = () => useContext(AppFrameContext)

const withAppFrame = Component => props => {
  return <AppFrameConsumer>{data => <Component {...props} appFrame={data} />}</AppFrameConsumer>
}

export { AppFrameProvider, AppFrameConsumer, withAppFrame }
