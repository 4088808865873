import React, { createContext, useContext, useEffect, useMemo } from 'react'
import { useMember, useUpdateMember } from 'shared-ui/components/pages/PageWrapper'
import io from 'socket.io-client'
import { useApi } from 'shared-ui/api/ApiContext'
import useEvent from 'react-use-event-hook'

const WebsocketContext = createContext(null)

const apiUrl = process.env.REACT_APP_API_URL

export default function WebsocketEvents({ children }) {
  const member = useMember()
  const api = useApi()
  const updateMember = useUpdateMember()

  const updateMergedMember = useEvent(data => {
    updateMember?.({ ...member, ...data })
  })

  const onConnect = useEvent(() => {
    socket.emit('join', member.socketChannel)
    console.log('connected to websocket ...')
  })
  const onUpdateJPoints = useEvent(points => {
    const { jpoints } = member
    if (points === jpoints) return
    updateMergedMember({ jpoints: points })
  })

  const onUpdateLoyaltyTier = useEvent(tier => {
    const { tier: currentTier } = member
    if (currentTier && currentTier._id === tier._id) return
    updateMergedMember({ tier })
  })

  const onUpdateNumCartItems = useEvent(numItems => {
    const { numCartItems } = member
    if (numCartItems === numItems) return
    updateMergedMember({ numCartItems: numItems })
  })

  const onNavigateTo = useEvent(href => {
    api.connector.onNavigateTo(href)
  })

  const onShowMessage = useEvent(msg => {
    const { Description } = msg
    console.log(msg)
    if (window.confirm(Description)) api.connector.onNavigateTo('/agreements')
  })

  const socket = useMemo(() => {
    if (!member?.socketChannel) return null
    const res = /^(https?:\/\/)?(.*?)\/(.+)$/gi.exec(apiUrl)
    return io(res[2] || '/', { path: `/${res[3]}/socket`, transports: ['websocket'] })
  }, [member?.socketChannel])

  useEffect(() => {
    if (!socket) return
    socket.once('connect', onConnect)
    socket.on('updateJPoints', onUpdateJPoints)
    socket.on('updateLoyaltyTier', onUpdateLoyaltyTier)
    socket.on('updateNumCartItems', onUpdateNumCartItems)
    socket.on('navigateTo', onNavigateTo)
    socket.on('showMessage', onShowMessage) // C360 hardcoded demo stuff.
    socket.on('disconnect', () => {
      console.log('disconnected websocket ...')
    })
  }, [socket])

  return <WebsocketContext.Provider value={socket}>{children}</WebsocketContext.Provider>
}

export const useWebsocket = () => useContext(WebsocketContext)
