import axios from 'axios'
import queryString from 'query-string'
import Subscriber from 'shared-ui/utils/Subscriber'
import captcha from 'shared-ui/utils/captcha'

axios.defaults.withCredentials = true

export default class ApiConnector {
  alertSubscriber = new Subscriber()
  barcodeSubscriber = new Subscriber()
  onNavigateTo
  _createCaptchaToken

  constructor(baseUrl) {
    this.baseUrl = baseUrl
  }

  setupCaptcha(captchaId, captchaVisibility) {
    this._createCaptchaToken = captcha(captchaId, captchaVisibility)
  }

  listenToAlerts = listener => {
    return this.alertSubscriber.subscribe(listener)
  }

  listenToBarcodes = listener => {
    return this.barcodeSubscriber.subscribe(listener)
  }

  makeRequest(url, method, config = {}, data) {
    const source = axios.CancelToken.source()
    config = { ...config, cancelToken: source.token }

    let request

    if (data !== undefined) {
      if (config && config.newTab) {
        window.open(`${this.baseUrl}${url}?${queryString.stringify(data)}`, '_blank')
        return
      }

      if (method === 'post' && this._createCaptchaToken)
        request = this._createCaptchaToken('default')

      if (request)
        request = request.then(token => {
          if (data instanceof FormData) data.append('token', token)
          else data = { ...data, token }

          if (!config.headers) config.headers = {}
          config.headers['x-captcha-token'] = token

          return axios[method](this.baseUrl + url, data, config)
        })
      else request = axios[method](this.baseUrl + url, data, config)
    } else {
      request = axios[method](this.baseUrl + url, config)
    }

    request = request
      .then(response => response.data)
      .then(data => {
        const { navigateTo, alert, scan } = data

        if (navigateTo) {
          this.onNavigateTo && this.onNavigateTo(navigateTo)
        }

        if (alert) {
          this.dispatchAlert(alert)
        }

        if (scan) {
          this.dispatchBarcode(scan)
        }

        return data
      })
      .catch(error => {
        if (axios.isCancel(error)) {
          // since this request is cancelled
          // we don't want it to resolve/reject anymore
          return new Promise(() => {})
        }

        const { response: { data: { navigateTo, alert } = {} } = {} } = error

        if (navigateTo) {
          this.onNavigateTo && this.onNavigateTo(navigateTo)
        }

        if (alert) {
          this.dispatchAlert(alert)
        }

        throw error
      })

    return {
      request,
      cancel: () => {
        source.cancel('cancelled')
      },
    }
  }

  dispatchAlert = alert => {
    this.alertSubscriber.dispatch(alert)
  }

  dispatchBarcode = data => {
    this.barcodeSubscriber.dispatch(data)
  }

  get = (url, config, data) => {
    return this.makeRequest(url, 'get', config, data)
  }

  delete = (url, config) => {
    return this.makeRequest(url, 'delete', config)
  }

  post = (url, data, config) => {
    return this.makeRequest(url, 'post', config, data)
  }

  put = (url, data, config) => {
    return this.makeRequest(url, 'put', config, data)
  }

  patch = (url, data, config) => {
    return this.makeRequest(url, 'patch', config, data)
  }
}
