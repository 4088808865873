export default class IntlUtil {
  static _labels
  static _locale
  static _currency
  static _supportedLocales = []

  static init({ defaultLabels = {} }) {
    IntlUtil._labels = defaultLabels
  }

  static set labels(value) {
    IntlUtil._labels = { ...IntlUtil._labels, ...value }
  }

  static set currency(currency) {
    currency = currency || 'CHF'

    if (currency === IntlUtil._currency) {
      return
    }

    IntlUtil._currency = currency

    if (IntlUtil._locale) {
      IntlUtil._currencyFormat = new Intl.NumberFormat(IntlUtil._locale, {
        style: 'currency',
        currency: IntlUtil._currency,
      })
    }
  }

  static set supportedLocales(locales) {
    IntlUtil._supportedLocales = locales || ['de-ch']
  }

  static get supportedLocales() {
    return IntlUtil._supportedLocales
  }

  static set locale(locale) {
    if (!IntlUtil._supportedLocales.includes(locale)) locale = IntlUtil._supportedLocales[0]

    if (locale === IntlUtil._locale) {
      return
    }

    IntlUtil._locale = locale

    IntlUtil._numberFormat = new Intl.NumberFormat(locale)

    IntlUtil._percentage = new Intl.NumberFormat(locale, {
      style: 'percent',
      maximumFractionDigits: 1,
      minimumFractionDigits: 0,
    })

    IntlUtil._defaultDateFormat = new Intl.DateTimeFormat(locale, {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    })

    IntlUtil._defaultDateTimeFormat = new Intl.DateTimeFormat(locale, {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    })

    if (IntlUtil._currency) {
      IntlUtil._currencyFormat = new Intl.NumberFormat(locale, {
        style: 'currency',
        currency: IntlUtil._currency,
      })
    }
  }

  static get locale() {
    return IntlUtil._locale
  }

  static label = id => {
    if (IntlUtil._labels && id in IntlUtil._labels) {
      if (process.env.NODE_ENV !== 'production') {
        return '[' + IntlUtil._labels[id] + ']'
      }
      return IntlUtil._labels[id]
    }

    return '{{' + id + '}}'
  }

  static num = value => {
    return IntlUtil._numberFormat.format(value)
  }

  static commarize(value, min) {
    min = min || 1e7

    if (value >= min) {
      const units = ['k', 'M', 'B', 'T']
      const order = Math.floor(Math.log(value) / Math.log(1000))
      const unitname = units[order - 1]
      const num = Math.floor(value / 1000 ** order)

      return num + unitname
    }

    return value ? IntlUtil.num(value) : value
  }

  static percentage = (value = 0, usePlus = false) => {
    let format = IntlUtil._percentage.format(value)
    if (value > 0) {
      format = `${usePlus ? '+' : ''}${format}`
    }
    return format
  }

  static price = (value, options) => {
    if (options === undefined) {
      return IntlUtil._currencyFormat.format(value)
    }

    return new Intl.NumberFormat(IntlUtil._locale, {
      style: 'currency',
      currency: IntlUtil._currency,
      ...options,
    }).format(value)
  }

  static date = (value, options) => {
    const initialValue = value
    // handling various formats value might have
    if (typeof value === 'string') value = new Date(initialValue).getTime()
    if (isNaN(value)) {
      value = new Date(Number(initialValue)).getTime()
      if (isNaN(value)) return `{${initialValue}}`
    }

    if (options === undefined) {
      return IntlUtil._defaultDateFormat.format(value)
    }

    return new Intl.DateTimeFormat(IntlUtil._locale, options).format(value)
  }

  static dateTime = (value, options) => {
    if (typeof value === 'string') {
      value = new Date(value)
    }

    if (options === undefined) {
      return IntlUtil._defaultDateTimeFormat.format(value)
    }

    return new Intl.DateTimeFormat(IntlUtil._locale, options).format(value)
  }

  static timePast = timeInMs => {
    let seconds = Math.floor(timeInMs / 1000) % 60
    let minutes = Math.floor(timeInMs / 1000 / 60) % 60
    let hours = Math.floor(timeInMs / 1000 / 60 / 60) % 60

    let timeAsDate = new Date(0, 0, 0, hours, minutes, seconds)

    return IntlUtil.date(timeAsDate, {
      hour: hours > 0 ? '2-digit' : undefined,
      minute: '2-digit',
      second: '2-digit',
    })
  }

  static getWeekdaysLabels = (length = 'long') => {
    const weekDays = []
    for (let i = 4; i < 11; i++) {
      let weekday = new Intl.DateTimeFormat([IntlUtil.locale], {
        weekday: length,
      }).format(new Date(2021, 0, i))
      weekDays.push(weekday)
    }
    return weekDays
  }

  static getMonthLabels = (length = 'long') => {
    const months = []
    for (let i = 0; i < 12; i++) {
      let month = new Intl.DateTimeFormat([IntlUtil.locale], {
        month: length,
      }).format(new Date(2021, i, 1))
      months.push(month)
    }
    return months
  }

  /**
   * Check if the string value is a valid javascript date
   * @param {string|Date} value - date value
   */
  static isValidDate = value => {
    const date = new Date(value)
    return !(value && isNaN(date.getTime()))
  }
  static timerFormat = seconds => {
    const minutes = Math.floor(seconds / 60)
    const secondsLeft = seconds % 60
    const formattedTime = `${String(minutes).padStart(2, '0')}:${String(secondsLeft).padStart(
      2,
      '0',
    )}`
    return formattedTime
  }
}
