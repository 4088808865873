import React, { useEffect } from 'react'
import { useSnackbar } from 'notistack'
import { CustomSnackBarContent } from 'shared-ui/components/atoms/CustomSnackBar'
import { withApi } from 'shared-ui/api/ApiContext'

const SnackMessages = ({ api: { connector } = {}, closeSnackbar }) => {
  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    let errorSubscription

    if (connector) {
      errorSubscription = connector.listenToAlerts(onAlert)
    }

    return () => {
      errorSubscription && errorSubscription.unsubscribe()
    }
  }, [connector])

  const onAlert = alert => {
    const { message, stack, variant, dontHide = false } = alert

    if (message === undefined) {
      return
    }

    enqueueSnackbar(message + (stack ? ': ' + stack : ''), {
      anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
      autoHideDuration: 6000,
      persist: dontHide,
      variant: variant,
      children: key => (
        <CustomSnackBarContent
          onClose={() => handleClose2(key)}
          message={message + (stack ? ': ' + stack : '')}
          variant={variant}
        />
      ),
    })
  }

  const handleClose2 = key => {
    closeSnackbar(key)
  }

  return <div></div>
}

export default withApi(SnackMessages)
