import { useMember } from 'shared-ui/components/pages/PageWrapper'
import useEvent from 'react-use-event-hook'
import { useApi } from 'shared-ui/api/ApiContext'
import useEffectOnce from 'shared-ui/hooks/useEffectOnce'
import AnalyticsEvents from 'shared-ui/utils/AnalyticsEvents'
import { useAppFrame } from 'shared-ui/components/AppFrameContext'

export default function GoogleServices() {
  const appFrame = useAppFrame()
  const member = useMember()
  const api = useApi()

  const technical = appFrame?.settings?.technical
  const language = appFrame?.settings?.language

  // Google Analytics
  const initGAEvent = useEvent(() => {
    AnalyticsEvents.init(
      technical.gtmId,
      technical.gtmSettings,
      language.currency,
      technical.addTags,
    )
  })
  useEffectOnce(() => {
    if (!technical?.gtmId) return false
    initGAEvent()
  }, [technical?.gtmId, initGAEvent])

  // Firebase Web Notifications
  const firebaseClient = technical?.firebaseClient

  const initFirebaseEvent = useEvent(() => {
    void initialiseFirebase(api, firebaseClient)
  })

  useEffectOnce(() => {
    if (!member || !firebaseClient) return false
    initFirebaseEvent()
  }, [member, firebaseClient, initFirebaseEvent])

  return null
}

async function initialiseFirebase(api, firebaseClient) {
  const { firebase } = window
  if (!firebase || !firebase.messaging.isSupported())
    return console.warn('Push notifications are currently not supported')
  firebase.initializeApp(firebaseClient)

  const messaging = firebase.messaging()
  const permission = await Notification.requestPermission().catch(console.error)
  if (permission !== 'granted') return console.warn('Notification not granted')

  const fbSwRegistration = await navigator.serviceWorker
    .register('/v1/serviceworker.js')
    .catch(console.error)
  const { vapidKey } = firebaseClient

  messaging.usePublicVapidKey(vapidKey)
  messaging.useServiceWorker(fbSwRegistration)

  messaging.onMessage(payload => {
    const {
      notification: { body, title },
      data,
    } = payload

    api.connector.dispatchAlert({
      message: `${title} - ${body}`,
      variant: 'info',
      dontHide: true,
      customAction: data && data.slug,
    })
  })

  const currentToken = await messaging.getToken().catch(console.error)

  void api.registerToken(currentToken)
}
