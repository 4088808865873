import React from 'react'
import Icon from '@material-ui/core/Icon'
import Hidden from '@material-ui/core/Hidden'
import HeaderItemTemplate from '~components/molecules/HeaderItem/template'
import HeaderItem from '~components/molecules/HeaderItem'
import useUpdateAnimation from '~src/hooks/useUpdateAnimation'

function HeaderItemLoyalty(props) {
  const { classes, icon, member, label, ...rest } = props

  const { tier = {} } = member || {}
  const { name, icon: tierIcon, color } = tier

  const style = useUpdateAnimation(name, { duration: 0.6, scale: 1.5 })

  if (!name) return <HeaderItem {...props} />

  return (
    <Hidden xsDown>
      <HeaderItemTemplate label={name} {...rest}>
        <Icon className="text-grey-400" style={color ? { color: color, ...style } : style}>
          {tierIcon}
        </Icon>
      </HeaderItemTemplate>
    </Hidden>
  )
}

export default HeaderItemLoyalty
