import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import FooterItem from '~components/atoms/FooterItem'
import IntlUtil from 'shared-ui/utils/IntlUtil'
import PortalLabels from '~src/constants/PortalLabels'
import classNames from 'classnames'

const components = {
  FooterItem,
}

const styles = ({ palette, breakpoints, spacing: { unit } }) => ({
  bar: {
    backgroundColor: palette.paper,
    [breakpoints.down('sm')]: {
      textAlign: 'center',
    },
    paddingTop: '10px',
    borderTop: `1px solid ${palette.grey200}`,
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    [breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
      paddingTop: unit * 2,
      paddingBottom: unit * 2,
    },
  },
  copyright: {
    color: palette.grey400,
  },
  rightItem: {
    color: palette.grey500,
    [breakpoints.down('xs')]: {
      '&:last-child': {
        marginBottom: unit,
      },
    },
  },
  content: {
    flexWrap: 'wrap',
    flexBasis: 'auto', // ie 11
    width: 'auto',
    margin: unit * 2,
    [breakpoints.down('xs')]: {
      justifyContent: 'center',
      flexDirection: 'column',
      marginTop: unit * 5,
    },
    [breakpoints.up('sm')]: {
      marginRight: 6 * unit,
    },
  },
  recaptchaBranding: {
    position: 'absolute',
    fontSize: '13px',
    top: '4px',
    right: '7px',
    padding: '4px',
    minWidth: unit * 2,
    marginTop: unit,
    color: palette.grey100,
    '& a': {
      color: palette.grey500,
    },
  },
})

const Footer = withStyles(styles)(
  class Footer extends React.Component {
    render() {
      const {
        classes,
        className,
        data: { items = [] },
        captchaBrandingVisibility,
      } = this.props
      return (
        <Toolbar disableGutters className={`${className} ${classes.bar}`}>
          {captchaBrandingVisibility && (
            <div className={classNames(classes.content, classes.recaptchaBranding)}>
              <Typography variant={'caption'} className={classes.copyright}>
                This site is protected by reCAPTCHA and the Google{' '}
                <a
                  href="https://policies.google.com/privacy"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Privacy Policy
                </a>{' '}
                AdminLoginPage{' '}
                <a
                  href="https://policies.google.com/terms"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Terms of Service
                </a>{' '}
                apply.
              </Typography>
            </div>
          )}
          <div className={classes.container}>
            <div className={classes.content}>
              <Typography variant={'caption'} className={classes.copyright}>
                {IntlUtil.label(PortalLabels.FOOTER_COPYRIGHT)}
              </Typography>
            </div>
            <div className={classes.content}>
              {items.map(({ props, component }, index) => {
                const ItemComponent = components[component]
                return (
                  <ItemComponent key={'item_' + index} {...props} className={classes.rightItem} />
                )
              })}
            </div>
          </div>
        </Toolbar>
      )
    }
  },
)

export default Footer
