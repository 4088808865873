import React from 'react'
import 'shared-ui/assets/styles/global.css'
import App from './App'
import { createRoot } from 'react-dom/client'

const errorsToWarn = ['Warning:']
const oldConsError = console.error

console.error = function (...args) {
  let toWarn = typeof args[0] === 'string' && errorsToWarn.some(s => args[0].startsWith(s))
  toWarn ? console.warn(...args) : oldConsError(...args)
}

const root = createRoot(document.getElementById('root'))
root.render(<App />)
